import React from 'react'
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import {Footer} from '../components';
import '../layouts/index.scss';

require("prismjs/themes/prism.css");


const TemplateWrapper = ({ children }) => (
  <div className="layout">
    <Helmet
      title="Alexandre Gomes - JavaScript developer"
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    />
    {/* <Header/> */}
    <div className="layout-content">
      {children}
    </div>
    <Footer/>
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
};

export default TemplateWrapper;
