import React from 'react';
import Layout from "../components/layout"
import Link from 'gatsby-link';
import { Flex, Box } from '@rebass/grid';
import {graphql} from 'gatsby'

import '../sass/post-preview.scss';

const IndexPage = ({data}) => {
  const { allMarkdownRemark: posts } = data;
  return (
  <Layout>
    {posts.edges.map(({node: post}) =>
      <Flex className="post-preview-container" justifyContent='center'>
        <Box width={[9/10, 4/6, 1/2]} className="post-preview">
          <Link
          to={post.frontmatter.path}>
            <h1 className="
				font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl 
				hover:text-indigo-500
				transition duration-400 ease-in-out
			  ">{post.frontmatter.title}</h1>
          </Link>
          <p className="
			  font-normal text-gray-600 text-sm md:text-base
			">Posted on {post.frontmatter.date}</p>
          {/* <div
            className="blog-post--content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          /> */}
        </Box>
      </Flex>
    )}
  </Layout>
  );
}
export const pageQuery = graphql`
  query allNodesQuery {
    allMarkdownRemark(sort:{fields:[frontmatter___date], order:DESC}) {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
          }
        }
      }
    }
  }`;
export default IndexPage;
