import React from 'react';
import {Flex, Box} from '@rebass/grid';

import GatsbyLogo from '../../static/img/gatsby-logo.svg';
import ReactLogo from '../../static/img/react-logo.svg';
import GraphQlLogo from '../../static/img/graphql-logo.svg';

const Footer = () => (
  <Flex className="footer" justifyContent='center'>
    <Box width={[1/1, 3/6]} className="footer-content">
        <p> Powered by </p>
        <Flex>
          <Box width={1/3}>
          <span className="footer-content--image-container">
            <GatsbyLogo/>
          </span>
          </Box>
          <Box width={1/3}>
          <span className="footer-content--image-container">
            <ReactLogo/>
          </span>
          </Box>
          <Box width={1/3}>
          <span className="footer-content--image-container">
            <GraphQlLogo/>
          </span>
          </Box>
        </Flex>
    </Box>
  </Flex>
);

export default Footer;
